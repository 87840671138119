import { lazy, Suspense } from "react";
import { createRoot } from "react-dom/client";

import type * as AppModule from "./App";
import throwError from "./utils/throwError";

const LazyLoadedApp = lazy(() => {
  return new Promise<typeof AppModule>((resolve) => {
    // we want to start importing right away, so fetching will be already done when resolved
    const mod = import("./App");

    setTimeout(() => resolve(mod), 1000);
  });
});

function App() {
  return (
    <Suspense>
      <LazyLoadedApp />
    </Suspense>
  );
}

createRoot(document.getElementById("root") ?? throwError("Could not find `root` DOM element.")).render(<App />);
